<template>
<!-- 智慧化健身房  -->
    <div>
        <div class="posit-left">
            <peopleleft style="margin-bottom:0.18rem;"></peopleleft>
            <jianshen></jianshen>
        </div>
        <div class="posit-top">
            <peopletop></peopletop>
        </div>
        <div class="posit-right">
            <jianshenfang style="margin-bottom:0.33rem;"></jianshenfang>
            <exercise></exercise>
        </div>
    </div>
</template>

<script>
export default {
    components:{
        peopleleft: () => import('../charts/peopleone/peopleleft.vue'),
        jianshen: () => import('../charts/peopleone/jianshen.vue'),
        peopletop: () => import('../charts/peopleone/peopletop.vue'),
        jianshenfang: () => import('../charts/peopleone/jianshenfang.vue'),
        exercise: () => import('../charts/peopleone/exercise.vue')
    },
}
</script>

<style scoped>

</style>